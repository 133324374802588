@import './shared/styles/_vuetify-overrides.scss';









































.cassie-radio-panel-parent {
	margin-bottom: 10px;
}
.cassie-radio {
	&-reverse-label {
		display: flex;
		flex-direction: row-reverse;

		.v-label {
			margin-right: 10px;
		}
	}
	&-panel {
    padding: 15px 5px;
    background-color: #f8f9fd !important;
	}
	.cassie-radio-panel-parent:has(&-panel.v-item--active), &-panel.v-item--active {
		background-color: #DDF9F8 !important;
	}
}
