@import './shared/styles/_vuetify-overrides.scss';










































































.cassie-stepper {
	background: none !important;
	box-shadow: none !important;
	.v-stepper__header {
		hr {
			margin-top: 16px !important;
		}
	}
}
